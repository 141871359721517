import styled from '@emotion/styled';

const List = styled('ul')(({ theme }) => ({
  backgroundColor: theme.colors.transparent.primary,
  listStyle: 'none',
  margin: `0 0 0 -${theme.dimensions.gutter}`,
  padding: '0 0 1.2rem 0',
}));

export default List;
