import styled from '@emotion/styled';

const Button = styled('button')(({ theme }) => ({
  background: theme.colors.transparent.dark,
  border: 'none',
  borderRadius: '0.5rem',
  cursor: 'pointer',
  height: '100%',
  overflow: 'hidden',
  outline: 'none',
  padding: 0,
  position: 'relative',
  width: '100%',
}));

export default Button;
