import Button from './primitives/Button';
import GenreCheck from './GenreCheck';
import Image from './primitives/Image';
import Item from './primitives/Item';
import type { IGetTranslateFunctionResponse } from 'redux-i18n';

type Props = {
  active: boolean;
  id: number;
  logo: string;
  name: string;
  onGenreClick: (id: number) => void;
  translate: IGetTranslateFunctionResponse;
};

const GenreBtn = (props: Props) => {
  const { active, onGenreClick, id, logo, translate } = props;
  const genreImageStyle = {
    backgroundImage: `url("${logo.replace(/http:/g, '')}")`,
  };

  return (
    <Item data-test="genre-game-item">
      <Button
        data-id={id}
        data-test="genre-game-button"
        onClick={() => onGenreClick(id)}
        type="button"
      >
        <Image active={active} style={genreImageStyle} />
        <GenreCheck active={active} ariaLabel={translate('Pick Genre')} />
      </Button>
    </Item>
  );
};

export default GenreBtn;
