import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const GenreGameHeader = styled('header')(({ theme }) => ({
  paddingBottom: '2.5rem',

  h1: {
    fontSize: theme.fonts.size.large,
    fontWeight: 'bold',
    lineHeight: '4.86rem',
  },

  h2: {
    fontSize: '2.2rem',
    fontWeight: 700,
    margin: 0,
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    h1: {
      fontSize: theme.fonts.size.medium,
      lineHeight: '1.1em',
    },
    h2: {
      fontSize: theme.fonts.size.small,
      lineHeight: '2.6rem',
      paddingTop: '.5rem',
    },
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    h2: {
      fontSize: theme.fonts.size.small,
      lineHeight: '2.4rem',
      paddingTop: 0,
    },
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['370'])]: {
    h1: {
      fontSize: theme.fonts.size.medium,
    },

    h2: {
      fontSize: theme.fonts.size.small,
      lineHeight: '2.4rem',
      paddingTop: 0,
    },
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['320'])]: {
    h1: {
      fontSize: '1.9rem',
    },

    h2: {
      fontSize: theme.fonts.size.small,
      lineHeight: '2.0rem',
      paddingTop: '.5rem',
    },
  },
}));

export default GenreGameHeader;
