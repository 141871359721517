import Container from './primitives/Container';
import CtaButtonWrapper from './primitives/CtaButtonWrapper';
import FilledButton from 'primitives/Buttons/FilledButton';
import GenreBtn from './GenreBtn';
import GenreGameFooter from './primitives/GenreGameFooter';
import GenreGameHeader from './primitives/GenreGameHeader';
import List from './primitives/List';
import Table from './primitives/Table';
import usePageView from 'modules/Analytics/usePageView';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import {
  fetchGenrePreferences,
  saveGenrePreferences,
} from 'state/Genres/actions';
import { getGenres, getSelectedGenres } from 'state/Genres/selectors';
import { getIsAnonymous } from 'state/Session/selectors';
import { getListenHistory } from 'state/Stations/selectors';
import { isEqual } from 'lodash-es';
import { navigate } from 'state/Routing/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import type { Genre } from 'state/Genres/types';

function GenreGame() {
  const [currentlySelected, setCurrentlySelected] = useState<
    Record<string, boolean>
  >({});
  const dispatch = useDispatch();
  const genres = useSelector(getGenres, isEqual);
  const isAnonymous = useSelector(getIsAnonymous);
  const listenHistory = useSelector(getListenHistory, isEqual);
  const selectedGenres = useSelector(getSelectedGenres, isEqual);
  const translate = useTranslate();

  usePageView({ pageName: 'genre_game' });

  useEffect(() => {
    if (!selectedGenres) {
      dispatch(fetchGenrePreferences());
      return;
    }

    setCurrentlySelected(selectedGenres);
  }, [dispatch, selectedGenres]);

  function toggleGenreClick(id: number) {
    const selected = { ...currentlySelected };
    selected[id] = !selected[id];
    setCurrentlySelected(selected);
  }

  async function handleGetStations() {
    await dispatch(saveGenrePreferences(currentlySelected, 'onboarding'));

    if (isAnonymous) {
      dispatch(navigate({ path: '/for-you/' }));
    } else {
      dispatch(navigate({ path: '/' }));
    }
  }

  return (
    <Container>
      <GenreGameHeader>
        <h1>{translate('Tell us all the genres you like.')}</h1>
        <h2>{translate("We'll suggest stations just For You.")}</h2>
      </GenreGameHeader>
      <Table
        data-test="genre-game-table"
        listenHistory={listenHistory.length > 0}
      >
        <List>
          {Object.values(genres)
            .sort((a, b) => (a.sortOrder >= b.sortOrder ? 1 : -1))
            .map(genre => {
              const { logo, name }: Genre = genre;
              return (
                <GenreBtn
                  active={!!currentlySelected[genre.id]}
                  id={genre.id}
                  key={`${genre.id}genre`}
                  logo={logo}
                  name={name}
                  onGenreClick={toggleGenreClick}
                  translate={translate}
                />
              );
            })}
        </List>
      </Table>
      <GenreGameFooter listenHistory={listenHistory.length > 0}>
        <CtaButtonWrapper>
          <FilledButton
            aria-label={translate('Get Stations')}
            data-test="get-stations-button"
            disabled={!Object.keys(currentlySelected).length}
            isBlock
            onClick={handleGetStations}
            styleType="cta"
          >
            {translate('Get Stations')}
          </FilledButton>
        </CtaButtonWrapper>
      </GenreGameFooter>
    </Container>
  );
}

export default GenreGame;
