import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { genreGame } from 'constants/assets';

const Container = styled('div')(({ theme }) => ({
  background: `no-repeat url("${genreGame}") rgba(5, 5, 5, 0.94)`,
  backgroundSize: 'cover',
  color: theme.colors.white.primary,
  height: '100%',
  overflowX: 'auto',
  paddingTop: '7rem',
  position: 'fixed',
  textAlign: 'center',
  top: 0,
  width: '100%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    color: theme.colors.white.primary,
    height: '100%',
    overflowX: 'auto',
    paddingTop: '7rem',
    textAlign: 'center',
    width: '100%',
  },
}));

export default Container;
