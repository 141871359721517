import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const CtaButtonWrapper = styled('div')(({ theme }) => ({
  margin: 'auto',
  padding: '1rem',
  width: '28rem',
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    width: '19rem',
  },
}));

export default CtaButtonWrapper;
