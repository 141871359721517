import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = { active: boolean };

const Check = styled('div')<Props>(({ active, theme }) => ({
  bottom: '.8rem',
  display: active ? 'inherit' : 'none',
  padding: '.6rem',
  position: 'absolute',
  right: '.8rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    bottom: '.5rem',
    padding: '.45rem',
    right: '.5rem',
  },

  '& svg': {
    height: '2.6rem',
    width: '2.6rem',

    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      height: '2rem',
      width: '2rem',
    },
  },
}));

export default Check;
