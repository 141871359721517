import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  listenHistory: boolean;
};

const Table = styled('div')<Props>(({ listenHistory = false, theme }) => ({
  backgroundColor: theme.colors.transparent.primary,
  bottom: listenHistory ? '21rem' : '13rem',
  display: 'block',
  left: '50%',
  margin: '0 auto 0 -59.8rem',
  overflowY: 'auto',
  padding: '2rem 0 0',
  position: 'fixed',
  top: '15rem',
  width: '119.6rem',
  MozMaskImage:
    '-moz-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
  OMaskImage:
    '-o-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
  msMaskImage:
    '-ms-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
  WebkitMaskImage:
    '-webkit-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
  maskImage:
    'linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    marginLeft: '-38.4rem',
    top: '12.2rem',
    width: '76.8rem',
    MozMaskImage:
      '-moz-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
    OMaskImage:
      '-o-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
    msMaskImage:
      '-ms-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
    WebkitMaskImage:
      '-webkit-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
    maskImage:
      'linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    bottom: listenHistory ? '19.5rem' : '14.5rem',
    marginLeft: '-18.5rem',
    paddingTop: '2rem',
    width: '37rem',
    MozMaskImage:
      '-moz-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
    OMaskImage:
      '-o-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
    msMaskImage:
      '-ms-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
    WebkitMaskImage:
      '-webkit-linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
    maskImage:
      'linear-gradient(bottom,  rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 98%, rgba(0,0,0,0) 100%)',
  },

  /* Scrollbar styles for genre game */
  '::-webkit-scrollbar': {
    width: '.8rem',
  },

  // /* Track */
  '::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.17)',
    borderRadius: '.6rem',
    marginBottom: '7%',
    marginTop: '7%',
    WebkitBorderRadius: '.6rem',
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    WebkitBorderRadius: '.6rem',
    background: 'rgba(255, 255, 255, 0.23)',
    borderRadius: '.6rem',
  },

  '::-webkit-scrollbar-thumb:window-inactive': {
    background: 'rgba(255, 0, 0, 0)',
  },
}));

export default Table;
