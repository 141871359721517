import Image from './Image';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const genreWidth = {
  base: 285,
  desktop: 242,
  phone: 155,
  tablet: 175,
};

const genreHeight = (width: number): string => `${(width * 3) / 5}px`;

const Item = styled('li')(({ theme }) => ({
  display: 'inline-block',
  height: genreHeight(genreWidth.base),
  paddingBottom: theme.dimensions.gutter,
  paddingLeft: theme.dimensions.gutter,
  width: `${genreWidth.base}px`,

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    height: genreHeight(genreWidth.desktop),
    width: `${genreWidth.desktop}px`,
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    height: genreHeight(genreWidth.tablet),
    width: `${genreWidth.tablet}px`,
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['320'])]: {
    height: genreHeight(genreWidth.phone),
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    width: `${genreWidth.phone}px`,
  },

  '&:hover': {
    [Image.toString()]: {
      opacity: 0.9,
    },
  },
}));

export default Item;
