import styled from '@emotion/styled';

type Props = { active: boolean };

const Image = styled('div')<Props>(({ active }) => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '100%',
  opacity: active ? 0.7 : 1,
  transition: 'opacity',
  width: '100%',
}));

export default Image;
