import Check from './primitives/Check';

type Props = {
  active: boolean;
  ariaLabel: string;
};

const GenreCheck = ({ active, ariaLabel }: Props) => (
  <Check active={active} aria-label={ariaLabel}>
    <svg
      fill="none"
      height="26"
      preserveAspectRatio="none"
      viewBox="0 0 28 28"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0001 0.666504C6.64008 0.666504 0.666748 6.63984 0.666748 13.9998C0.666748 21.3598 6.64008 27.3332 14.0001 27.3332C21.3601 27.3332 27.3334 21.3598 27.3334 13.9998C27.3334 6.63984 21.3601 0.666504 14.0001 0.666504ZM10.3867 19.7198L5.60008 14.9332C5.08008 14.4132 5.08008 13.5732 5.60008 13.0532C6.12008 12.5332 6.96008 12.5332 7.48008 13.0532L11.3334 16.8932L20.5067 7.71984C21.0267 7.19984 21.8668 7.19984 22.3867 7.71984C22.9067 8.23984 22.9067 9.07984 22.3867 9.59984L12.2667 19.7198C11.7601 20.2398 10.9067 20.2398 10.3867 19.7198Z"
        fill="white"
      />
    </svg>
  </Check>
);

export default GenreCheck;
