import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  listenHistory: boolean;
};

const GenreGameFooter = styled('div')<Props>(
  ({ listenHistory = false, theme }) => ({
    bottom: listenHistory ? '8rem' : '0',
    left: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 210,

    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      bottom: listenHistory ? '5rem' : '0',
    },

    '.copyright': {
      marginBottom: 0,
      padding: '0 1.5rem',

      [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
        position: 'absolute',
        width: '100%',
      },

      '.copyrightYear': {
        p: {
          margin: '3rem 0 0',

          [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
            margin: '1rem 0 0',
          },
        },
      },

      '.copyrightLinks': {
        ul: {
          margin: '3rem auto 0',

          [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
            margin: '0 auto',
          },
        },
      },
    },
  }),
);

export default GenreGameFooter;
